import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
import FirstComponent from './components/FirstComponent'
ReactDOM.render(
    <div>
      <p>Redirecting to https://devkit.shell.com/content/tools/HyperAutomation</p>
      {/* <App/> */}
        
      
    </div>,
    document.getElementById("root")
);